<template>
  <div class="register">
    <PageSubHeader :pageSubHeader="pageSubHeader" />
    <main>
      <section id="grocery_nonMember">
        <div class="w1300 layout_container">

          <div class="main_title_box mg-b-50">
            <h3 class="main_title txt-bold">積分申請</h3>
            <div class="img_box">
              <img src="@/statics/img/index/icon_title2.svg" alt="">
            </div>
          </div>

          <div class="main_description_box mg-b-50">
            <h4 class="main_description_title txt-light_green txt-bold">申請辦法</h4>
            <ul class="number_list mg-b-10" v-html="applyInfo"></ul>
            <p v-if="tel">聯絡電話：{{ tel }}</p>
            <p v-if="email">電子郵件：{{ email}}</p>
          </div>

          <!-- form area -->
          <div class="form_box">
            <form @submit.prevent>

              <div class="form_interactive">

                <TextInput
                  class="w50"
                  v-model.trim="applyData.apply_host"
                  :item="inputFormat.applyHostInput"
                  :rules="[
                    val => !!val || '必填欄位！',
                    val => val.length > 1 || '長度錯誤!',
                  ]"
                  :hasSubmited.sync="hasSubmited"
                />

                <TextInput
                  class="w50"
                  v-model.trim="applyData.contact"
                  :item="inputFormat.contactInput"
                  :rules="[
                    val => !!val || '必填欄位！',
                    val => val.length > 1 || '長度錯誤!',
                  ]"
                  :hasSubmited.sync="hasSubmited"
                />

                <TextInput
                  class="w50"
                  v-model.trim="applyData.contact_tel"
                  :item="inputFormat.telInput"
                  :rules="[
                    val => !!val || '必填欄位！',
                    val => val.length > 1 || '長度錯誤!',
                  ]"
                  :hasSubmited.sync="hasSubmited"
                />

                <TextInput
                  class="w50"
                  v-model.trim="applyData.contact_mail"
                  :item="inputFormat.emailInput"
                  :rules="[
                    val => !!val || '必填欄位！',
                    [emailRegex, '信箱格式錯誤'],
                  ]"
                  :hasSubmited.sync="hasSubmited"
                />

                <label class="w50 datepick_container">
                  <p>
                    <span class="txt-red txt-regular">*</span>
                    活動時間 <span class="txt-regular">(起)</span>
                  </p>
                  <date-pick
                    class="w100 no-readonly-style"
                    v-model="applyData.start_date"
                    :pickTime="true"
                    :format="'YYYY-MM-DD HH:mm'"
                    :inputAttributes="{
                      readonly: true,
                      placeholder: '請選擇活動時間(起)',
                    }"
                  ></date-pick>
                  <span class="err_message txt-red small">{{ startDateErrorMsg }}</span>
                </label>

                <label class="w50 datepick_container">
                  <p>
                    <span class="txt-red txt-regular">*</span>
                    活動時間 <span class="txt-regular">(迄)</span>
                  </p>
                  <date-pick
                    class="w100 no-readonly-style"
                    v-model="applyData.end_date"
                    :pickTime="true"
                    :format="'YYYY-MM-DD HH:mm'"
                    :inputAttributes="{
                      readonly: true,
                      placeholder: '請選擇活動時間(迄)',
                    }"
                  ></date-pick>
                  <span class="err_message txt-red small">{{ endDateErrorMsg }}</span>
                </label>

                <TextInput
                  class="w50"
                  v-model.trim="applyData.name"
                  :item="inputFormat.nameInput"
                  :rules="[
                    val => !!val || '必填欄位！',
                    val => val.length > 1 || '長度錯誤!',
                  ]"
                  :hasSubmited.sync="hasSubmited"
                />

                <TextInput
                  class="w50"
                  v-model.trim="applyData.place"
                  :item="inputFormat.placeInput"
                  :rules="[
                    val => !!val || '必填欄位！',
                    val => val.length > 1 || '長度錯誤!',
                  ]"
                  :hasSubmited.sync="hasSubmited"
                />

                <Select
                  class="w50"
                  v-model="applyData.hours"
                  :item="inputFormat.hourSelect"
                  :rules="[
                    val => !!val || '必選欄位！',
                  ]"
                  :hasSubmited.sync="hasSubmited"
                />

                <TextInput
                  class="w50"
                  v-model.trim="applyData.host"
                  :item="inputFormat.hostInput"
                  :rules="[
                    val => !!val || '必填欄位！',
                    val => val.length > 1 || '長度錯誤!',
                  ]"
                  :hasSubmited.sync="hasSubmited"
                />

                <Upload
                  class="w50"
                  v-model="applyData.files.class_schedule.file"
                  :item="inputFormat.scheduleUpload"
                  :rules="[
                    val => !!val || '請上傳檔案!',
                  ]"
                  :fileTitle.sync="applyData.files.class_schedule.title"
                  :hasSubmited.sync="hasSubmited"
                />

                <Upload
                  class="w50"
                  v-model="applyData.files.presenter.file"
                  :item="inputFormat.presenterUpload"
                  :rules="[
                    val => !!val || '請上傳檔案!',
                  ]"
                  :fileTitle.sync="applyData.files.presenter.title"
                  :hasSubmited.sync="hasSubmited"
                />

                <Password
                  class="w50"
                  v-model="applyData.password"
                  :item="inputFormat.passwordInput"
                  :rules="[
                    val => !!val || '必填欄位！',
                    val => val.length > 1 || '長度錯誤!',
                  ]"
                  :hasSubmited.sync="hasSubmited"
                />
              </div>

              <div class="form_submit txt-center">
                <button
                  type="button"
                  class="btn"
                  :class="{ btnDisable: isBtnDisable }"
                  @click="onSubmit"
                >送出</button>
              </div>

            </form>
          </div>

        </div>

      </section>
    </main>
  </div>
</template>

<script>
import PageSubHeader from '@/components/PageSubHeader.vue';
import { RegisterPointSubHeader, emailRegex } from '@/lib/const';
import TextInput from '@/components/form/TextInput.vue';
import Select from '@/components/form/Select.vue';
import Upload from '@/components/form/Upload.vue';
import Password from '@/components/form/Password.vue';
import DatePick from 'vue-date-pick';
import 'vue-date-pick/dist/vueDatePick.css';
import { mapActions } from 'vuex';
import moment from 'moment';
import { eventsApply } from '@/lib/http';
import _ from 'lodash';

export default {
  name: 'RegisterPointApply',
  data() {
    return {
      ...RegisterPointSubHeader,
      emailRegex,
      isBtnDisable: false,
      hasSubmited: false,

      tel: '0988-206590',
      email: 'hmca.tw@gmail.com',
      applyInfo: `
        <li>
          <p>請於課程活動開辦前一至六個月內提出申請，未依規定期限申請恕不受理。</p>
        </li>
        <li>
          <p>活動日期僅限填寫單一場次課程（同一個活動名稱及地點），如舉辦多場次（不同的活動名稱、日期時間及地點）請每場分開申請。</p>
        </li>
        <li>
          <p>未經本學會線上回覆認定前，請勿自行公告「台灣居家醫療繼續教育積分若干分」，或刊登類似之廣告。</p>
        </li>
        <li>
          <p>請依據本學會繼續教育積分申請辦法之規定檢附活動相關資料，包括「講師資料表與課程摘要」、「課程表」或「簡章」等。</p>
        </li>
        <li>
          <p>完成申請後，請自行至「積分申請查詢」查詢審核進度。</p>
        </li>
        <li>
          <p>申請案件通過審查者，請自行至「積分申請查詢」繳交行政處理費用，1-8小時活動1000元，9-16小時活動2000元，以此類推。</p>
        </li>
        <li>
          <p>為落實衛生福利部專科醫師繼續教育考核機制，請確實完成簽到及簽退機制。</p>
        </li>
        <li>
          <p>請於活動結束後1個月內至「積分申請查詢>上傳成果報告」上傳「出席簽名單掃描檔」、「積分登錄檔」及「授課講義」，辦理後續積分登錄事宜，逾期恕不計分。</p>
        </li>
        <li>
          <p>如有其他問題，請聯絡台灣居家醫療醫學會秘書處。</p>
        </li>
      `,
      applyData: {
        apply_host: '',
        contact: '',
        contact_tel: '',
        contact_mail: '',
        start_date: '',
        end_date: '',
        name: '',
        place: '',
        hours: '',
        host: '',
        password: '',
        files: {
          class_schedule: {
            title: '',
            file: null,
          },
          presenter: {
            title: '',
            file: null,
          },
        },
      },
      inputFormat: {
        applyHostInput: {
          label: '申請單位',
          type: 'text',
          placeholder: '請輸入申請單位',
          required: true,
        },
        contactInput: {
          label: '聯絡人',
          type: 'text',
          placeholder: '請輸入真實姓名',
          required: true,
        },
        telInput: {
          label: '聯絡電話',
          type: 'text',
          placeholder: '請輸入聯絡電話',
          required: true,
        },
        emailInput: {
          label: 'Email',
          type: 'email',
          placeholder: '請輸入完整Email',
          required: true,
        },
        nameInput: {
          label: '主題',
          type: 'text',
          placeholder: '請輸入主題名稱',
          required: true,
        },
        placeInput: {
          label: '活動地點',
          type: 'text',
          placeholder: '請輸入活動地點',
          required: true,
        },
        hourSelect: {
          label: '時數',
          placeHolder: '請選擇時數',
          options: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24'],
          required: true,
        },
        hostInput: {
          label: '主辦單位',
          type: 'text',
          placeholder: '請輸入主辦單位',
          required: true,
        },
        scheduleUpload: {
          label: '課程表',
          // reminder: '格式 zip/doc/docx/pdf。限制大小：4MB',
          subLabel: '格式 zip/doc/docx/pdf。限制大小：4MB',
          accept: '.zip,.doc,.docx,.pdf',
          size: 4 * 1000 * 1000,
          required: true,
        },
        presenterUpload: {
          label: '講師資料',
          // reminder: '格式 zip/doc/docx/pdf。限制大小：4MB',
          subLabel: '格式 zip/doc/docx/pdf。限制大小：4MB',
          accept: '.zip,.doc,.docx,.pdf',
          size: 4 * 1000 * 1000,
          required: true,
          sampleFile: '/download/講師資料表課程摘要-範本.doc',
        },
        passwordInput: {
          label: '自設密碼',
          reminder: '上傳成果報告用',
          placeholder: '請輸入自設密碼',
          required: true,
        },
      },
      startDateErrorMsg: '',
      endDateErrorMsg: '',

      fakeData: {
        apply_host: '測試單位',
        contact: '測試聯絡人',
        contact_tel: '0987654321',
        contact_mail: 'test@test.com',
        start_date: '2021-02-05 03:00',
        end_date: '2021-02-28 03:00',
        name: '測試主題',
        place: '測試地點',
        hours: 8,
        host: '測試主辦',
        password: 'asdf1234',
        files: {
          class_schedule: {
            title: '',
            file: null,
          },
          presenter: {
            title: '',
            file: null,
          },
        },
      },
    };
  },
  created() {
    // this.applyData = this.fakeData;
  },
  computed: {
    applyStartDate() {
      return this.applyData.start_date;
    },
    applyEndDate() {
      return this.applyData.end_date;
    },
  },
  methods: {
    ...mapActions([
      'openModal',
    ]),
    startDateTest() {
      if (!this.hasSubmited) {
        if (!this.applyData.start_date) {
          this.startDateErrorMsg = '必選欄位! 請選擇活動日期';
        } else {
          this.startDateErrorMsg = '';
        }
      }
    },
    endDateTest() {
      if (!this.hasSubmited) {
        if (!this.applyData.end_date) {
          this.endDateErrorMsg = '必選欄位! 請選擇活動日期';
        } else {
          this.endDateErrorMsg = '';
        }
      }
    },
    onSubmit() {
      this.hasSubmited = false;

      const errorList = [];
      this.$children.forEach((item) => {
        if (item.$options.name && item.$options.name.match(/^Form/i)) {
          item.test();
          errorList.push(item.error);
        }
      });
      this.startDateTest();
      if (this.startDateErrorMsg !== '') {
        errorList.push(true);
      }
      this.endDateTest();
      if (this.endDateErrorMsg !== '') {
        errorList.push(true);
      }
      if (errorList.indexOf(true) === -1) {
        if (moment(this.applyStartDate).isBefore(this.applyEndDate)) {
          this.isBtnDisable = true;

          /** 送過表單後，給予表單相關component一個flag通知停止watch */
          this.hasSubmited = true;

          eventsApply(this.applyData).then((result) => {
            if (_.get(result, 'data.status', false)) {
              this.openModal({
                message: '積分申請成功',
              });

              /** 返回頁面頂端 */
              window.scrollTo({
                top: 0,
                // behavior: 'smooth',
              });

              this.applyData.apply_host = '';
              this.applyData.contact = '';
              this.applyData.contact_tel = '';
              this.applyData.contact_mail = '';
              this.applyData.start_date = '';
              this.applyData.end_date = '';
              this.applyData.name = '';
              this.applyData.place = '';
              this.applyData.hours = '';
              this.applyData.host = '';
              this.applyData.password = '';
              this.applyData.files.class_schedule.title = '';
              this.applyData.files.class_schedule.file = null;
              this.applyData.files.presenter.title = '';
              this.applyData.files.presenter.file = null;

              this.isBtnDisable = false;
            } else {
              this.openModal({
                message: '積分申請失敗，詳請洽學會',
              });
              this.isBtnDisable = false;
            }
          });
        } else {
          this.openModal({
            message: '活動開始時間不可晚於結束時間！',
          });
        }
      } else {
        this.openModal({
          message: '必填欄位尚未完成！',
        });
      }
    },
  },
  watch: {
    applyStartDate() {
      this.startDateTest();
    },
    applyEndDate() {
      this.endDateTest();
    },
  },
  components: {
    PageSubHeader,
    TextInput,
    Select,
    Upload,
    Password,
    DatePick,
  },
};
</script>
