<template>
  <label :for="id" class="err_message_box">
    <p>
      <span class="txt-red txt-regular" v-if="item.required">*</span>
      {{ item.label }}
      <span class="txt-regular" v-if="item.reminder">({{ item.reminder }})</span>
    </p>
    <span class="err_message txt-red small">{{errorMsg}}</span>
    <input
      :type="type"
      :id="id"
      :value="value"
      :rules="rules"
      :error="error"
      :maxlength="maxlength"
      :placeholder="item.placeholder"
      @blur="test()"
      @input="$emit('input', $event.target.value)"
    >
    <img
      class="password_eye"
      src="@/statics/img/icon/remove_red_eye-24px.svg"
      alt=""
      @click="toggleInputType"
    >
  </label>
</template>

<script>
export default {
  name: 'FormPassword',
  props: {
    value: {},
    item: {},
    rules: Array,
    maxlength: Number,
  },
  data() {
    return {
      errorMsg: '',
      id: null,
      type: 'password',
    };
  },
  watch: {
    value(v) {
      this.errorMsg = this.validate(v) ? this.validate(v) : '';
      if (this.value === '') {
        this.errorMsg = '';
      }
    },
  },
  computed: {
    hasRules() {
      return this.rules !== undefined
        && this.rules !== null
        && this.rules.length > 0;
    },
    error() {
      if (this.hasRules && this.errorMsg !== '') {
        return true;
      }
      return false;
    },
  },
  methods: {
    toggleInputType() {
      if (this.type === 'password') {
        this.type = 'text';
      } else if (this.type === 'text') {
        this.type = 'password';
      }
    },
    test() {
      if (!this.item.required && !this.value) {
        return;
      }
      this.errorMsg = this.validate(this.value) ? this.validate(this.value) : '';
    },
    validate(val = this.value) {
      if (this.hasRules === true) {
        const errorMessage = [];
        this.rules.forEach((rule) => {
          if (typeof rule === 'function') {
            if (rule(val) !== true) {
              errorMessage.push(rule(val));
            }
          } else if (typeof rule === 'object') {
            if (!val.match(rule[0])) {
              errorMessage.push(rule[1]);
            }
          }
        });
        if (errorMessage.length) {
          return errorMessage[0];
        }
      }
      return '';
    },
  },
  mounted() {
    // eslint-disable-next-line no-underscore-dangle
    this.id = this._uid;
  },
};
</script>
