var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"register"},[_c('PageSubHeader',{attrs:{"pageSubHeader":_vm.pageSubHeader}}),_c('main',[_c('section',{attrs:{"id":"grocery_nonMember"}},[_c('div',{staticClass:"w1300 layout_container"},[_vm._m(0),_c('div',{staticClass:"main_description_box mg-b-50"},[_c('h4',{staticClass:"main_description_title txt-light_green txt-bold"},[_vm._v("申請辦法")]),_c('ul',{staticClass:"number_list mg-b-10",domProps:{"innerHTML":_vm._s(_vm.applyInfo)}}),(_vm.tel)?_c('p',[_vm._v("聯絡電話："+_vm._s(_vm.tel))]):_vm._e(),(_vm.email)?_c('p',[_vm._v("電子郵件："+_vm._s(_vm.email))]):_vm._e()]),_c('div',{staticClass:"form_box"},[_c('form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"form_interactive"},[_c('TextInput',{staticClass:"w50",attrs:{"item":_vm.inputFormat.applyHostInput,"rules":[
                  function (val) { return !!val || '必填欄位！'; },
                  function (val) { return val.length > 1 || '長度錯誤!'; } ],"hasSubmited":_vm.hasSubmited},on:{"update:hasSubmited":function($event){_vm.hasSubmited=$event},"update:has-submited":function($event){_vm.hasSubmited=$event}},model:{value:(_vm.applyData.apply_host),callback:function ($$v) {_vm.$set(_vm.applyData, "apply_host", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"applyData.apply_host"}}),_c('TextInput',{staticClass:"w50",attrs:{"item":_vm.inputFormat.contactInput,"rules":[
                  function (val) { return !!val || '必填欄位！'; },
                  function (val) { return val.length > 1 || '長度錯誤!'; } ],"hasSubmited":_vm.hasSubmited},on:{"update:hasSubmited":function($event){_vm.hasSubmited=$event},"update:has-submited":function($event){_vm.hasSubmited=$event}},model:{value:(_vm.applyData.contact),callback:function ($$v) {_vm.$set(_vm.applyData, "contact", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"applyData.contact"}}),_c('TextInput',{staticClass:"w50",attrs:{"item":_vm.inputFormat.telInput,"rules":[
                  function (val) { return !!val || '必填欄位！'; },
                  function (val) { return val.length > 1 || '長度錯誤!'; } ],"hasSubmited":_vm.hasSubmited},on:{"update:hasSubmited":function($event){_vm.hasSubmited=$event},"update:has-submited":function($event){_vm.hasSubmited=$event}},model:{value:(_vm.applyData.contact_tel),callback:function ($$v) {_vm.$set(_vm.applyData, "contact_tel", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"applyData.contact_tel"}}),_c('TextInput',{staticClass:"w50",attrs:{"item":_vm.inputFormat.emailInput,"rules":[
                  function (val) { return !!val || '必填欄位！'; },
                  [_vm.emailRegex, '信箱格式錯誤'] ],"hasSubmited":_vm.hasSubmited},on:{"update:hasSubmited":function($event){_vm.hasSubmited=$event},"update:has-submited":function($event){_vm.hasSubmited=$event}},model:{value:(_vm.applyData.contact_mail),callback:function ($$v) {_vm.$set(_vm.applyData, "contact_mail", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"applyData.contact_mail"}}),_c('label',{staticClass:"w50 datepick_container"},[_vm._m(1),_c('date-pick',{staticClass:"w100 no-readonly-style",attrs:{"pickTime":true,"format":'YYYY-MM-DD HH:mm',"inputAttributes":{
                    readonly: true,
                    placeholder: '請選擇活動時間(起)',
                  }},model:{value:(_vm.applyData.start_date),callback:function ($$v) {_vm.$set(_vm.applyData, "start_date", $$v)},expression:"applyData.start_date"}}),_c('span',{staticClass:"err_message txt-red small"},[_vm._v(_vm._s(_vm.startDateErrorMsg))])],1),_c('label',{staticClass:"w50 datepick_container"},[_vm._m(2),_c('date-pick',{staticClass:"w100 no-readonly-style",attrs:{"pickTime":true,"format":'YYYY-MM-DD HH:mm',"inputAttributes":{
                    readonly: true,
                    placeholder: '請選擇活動時間(迄)',
                  }},model:{value:(_vm.applyData.end_date),callback:function ($$v) {_vm.$set(_vm.applyData, "end_date", $$v)},expression:"applyData.end_date"}}),_c('span',{staticClass:"err_message txt-red small"},[_vm._v(_vm._s(_vm.endDateErrorMsg))])],1),_c('TextInput',{staticClass:"w50",attrs:{"item":_vm.inputFormat.nameInput,"rules":[
                  function (val) { return !!val || '必填欄位！'; },
                  function (val) { return val.length > 1 || '長度錯誤!'; } ],"hasSubmited":_vm.hasSubmited},on:{"update:hasSubmited":function($event){_vm.hasSubmited=$event},"update:has-submited":function($event){_vm.hasSubmited=$event}},model:{value:(_vm.applyData.name),callback:function ($$v) {_vm.$set(_vm.applyData, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"applyData.name"}}),_c('TextInput',{staticClass:"w50",attrs:{"item":_vm.inputFormat.placeInput,"rules":[
                  function (val) { return !!val || '必填欄位！'; },
                  function (val) { return val.length > 1 || '長度錯誤!'; } ],"hasSubmited":_vm.hasSubmited},on:{"update:hasSubmited":function($event){_vm.hasSubmited=$event},"update:has-submited":function($event){_vm.hasSubmited=$event}},model:{value:(_vm.applyData.place),callback:function ($$v) {_vm.$set(_vm.applyData, "place", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"applyData.place"}}),_c('Select',{staticClass:"w50",attrs:{"item":_vm.inputFormat.hourSelect,"rules":[
                  function (val) { return !!val || '必選欄位！'; } ],"hasSubmited":_vm.hasSubmited},on:{"update:hasSubmited":function($event){_vm.hasSubmited=$event},"update:has-submited":function($event){_vm.hasSubmited=$event}},model:{value:(_vm.applyData.hours),callback:function ($$v) {_vm.$set(_vm.applyData, "hours", $$v)},expression:"applyData.hours"}}),_c('TextInput',{staticClass:"w50",attrs:{"item":_vm.inputFormat.hostInput,"rules":[
                  function (val) { return !!val || '必填欄位！'; },
                  function (val) { return val.length > 1 || '長度錯誤!'; } ],"hasSubmited":_vm.hasSubmited},on:{"update:hasSubmited":function($event){_vm.hasSubmited=$event},"update:has-submited":function($event){_vm.hasSubmited=$event}},model:{value:(_vm.applyData.host),callback:function ($$v) {_vm.$set(_vm.applyData, "host", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"applyData.host"}}),_c('Upload',{staticClass:"w50",attrs:{"item":_vm.inputFormat.scheduleUpload,"rules":[
                  function (val) { return !!val || '請上傳檔案!'; } ],"fileTitle":_vm.applyData.files.class_schedule.title,"hasSubmited":_vm.hasSubmited},on:{"update:fileTitle":function($event){return _vm.$set(_vm.applyData.files.class_schedule, "title", $event)},"update:file-title":function($event){return _vm.$set(_vm.applyData.files.class_schedule, "title", $event)},"update:hasSubmited":function($event){_vm.hasSubmited=$event},"update:has-submited":function($event){_vm.hasSubmited=$event}},model:{value:(_vm.applyData.files.class_schedule.file),callback:function ($$v) {_vm.$set(_vm.applyData.files.class_schedule, "file", $$v)},expression:"applyData.files.class_schedule.file"}}),_c('Upload',{staticClass:"w50",attrs:{"item":_vm.inputFormat.presenterUpload,"rules":[
                  function (val) { return !!val || '請上傳檔案!'; } ],"fileTitle":_vm.applyData.files.presenter.title,"hasSubmited":_vm.hasSubmited},on:{"update:fileTitle":function($event){return _vm.$set(_vm.applyData.files.presenter, "title", $event)},"update:file-title":function($event){return _vm.$set(_vm.applyData.files.presenter, "title", $event)},"update:hasSubmited":function($event){_vm.hasSubmited=$event},"update:has-submited":function($event){_vm.hasSubmited=$event}},model:{value:(_vm.applyData.files.presenter.file),callback:function ($$v) {_vm.$set(_vm.applyData.files.presenter, "file", $$v)},expression:"applyData.files.presenter.file"}}),_c('Password',{staticClass:"w50",attrs:{"item":_vm.inputFormat.passwordInput,"rules":[
                  function (val) { return !!val || '必填欄位！'; },
                  function (val) { return val.length > 1 || '長度錯誤!'; } ],"hasSubmited":_vm.hasSubmited},on:{"update:hasSubmited":function($event){_vm.hasSubmited=$event},"update:has-submited":function($event){_vm.hasSubmited=$event}},model:{value:(_vm.applyData.password),callback:function ($$v) {_vm.$set(_vm.applyData, "password", $$v)},expression:"applyData.password"}})],1),_c('div',{staticClass:"form_submit txt-center"},[_c('button',{staticClass:"btn",class:{ btnDisable: _vm.isBtnDisable },attrs:{"type":"button"},on:{"click":_vm.onSubmit}},[_vm._v("送出")])])])])])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main_title_box mg-b-50"},[_c('h3',{staticClass:"main_title txt-bold"},[_vm._v("積分申請")]),_c('div',{staticClass:"img_box"},[_c('img',{attrs:{"src":require("@/statics/img/index/icon_title2.svg"),"alt":""}})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('span',{staticClass:"txt-red txt-regular"},[_vm._v("*")]),_vm._v(" 活動時間 "),_c('span',{staticClass:"txt-regular"},[_vm._v("(起)")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('span',{staticClass:"txt-red txt-regular"},[_vm._v("*")]),_vm._v(" 活動時間 "),_c('span',{staticClass:"txt-regular"},[_vm._v("(迄)")])])}]

export { render, staticRenderFns }